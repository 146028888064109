const parse = require('url-parse')

export const normalizeDomain = (store) => {
  const { pathname } = parse(store.state.kiosk.domain, {})
  const parts = pathname.split('/')

  if (parts.length > 1) {
    store.commit('kiosk/SET_DOMAIN', parts.shift())
  }
}

export default (store) => {
  normalizeDomain(store)
}
